import { useEffect, useState } from "react"
import Fuse from "fuse.js"
import Link from "next/link"
import { useRouter } from "next/router"
import Meta from "components/Meta"
import PageHeader from "components/DynamicComponent/atoms/PageHeader"
import { getGlobals } from "storyblok/api"

export default function Page({ meta }) {
  const router = useRouter()
  const [results, setResults] = useState()

  useEffect(() => {
    const URL = /https?:\/\/.*(?=<\/loc>)/gim

    async function getSitemapURLs() {
      const origin = process.env.NEXT_PUBLIC_SITE_URL

      const index = await fetch("/sitemap.xml")
      const body = await index.text()
      const sitemapURLs = body.match(URL)

      const URLs = await Promise.all(
        sitemapURLs.map(async (sitemapURL) => {
          const sitemap = await fetch(sitemapURL.replace(origin, ""))
          const body = await sitemap.text()
          const URLs = body
            .match(URL)
            .map((URL) => URL.replace(origin, ""))
            .filter((URL) => URL !== "")

          return URLs
        })
      )

      const fuse = new Fuse(URLs.flat(), {
        ignoreLocation: true,
        threshold: 0.4,
      })
      const results = fuse.search(router.asPath)
      setResults(results.slice(0, 5))
    }

    getSitemapURLs()
  }, [router.asPath])

  return (
    <>
      <Meta info={meta} />
      <main>
        <PageHeader blok={{ heading: "404 page not found" }} />
        <section className="px-8 py-12">
          <div className="max-w-screen-xl mx-auto">
            <h2 className="text-m1 lg:text-m2 max-w-3xl font-primary leading-7 lg:leading-9 pb-8">
              Sorry, the page you&#39;re trying to access could not be found. It may have been deleted or moved.
            </h2>
            {results?.length ? (
              <>
                <h3 className="text-m1 font-secondary font-bold pb-4">Did you mean...</h3>
                <ul className="pb-12">
                  {results.map((result) => (
                    <li className="pt-6 first:pt-0" key={result.item}>
                      <Link
                        className="underline font-primary text-purple visited:text-blue hover:no-underline"
                        href={result.item}
                      >
                        {result.item}
                      </Link>
                    </li>
                  ))}
                </ul>
              </>
            ) : null}
          </div>
        </section>
      </main>
    </>
  )
}

export async function getStaticProps({ preview = null }) {
  const globals = await getGlobals()

  return {
    props: {
      preview,
      globals,
    },
  }
}
